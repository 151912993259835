export const environment = {
  production: true,
  apiUrl: 'https://1rq7uddohi.execute-api.us-east-1.amazonaws.com/Prod/api/',

  //aws s3
  accessKeyId: 'AKIATS4AYSRQPIU2K7VE',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucket: 's3siquede',
  BasePath: 'Dev',

  //Login Azure
  redirectAplicationAzure: '',
  scopesAzure: 'api://1f4e3786-7836-47fc-aeda-b8f318ae1f66/Auth.AD',
  clientIdAzure: 'ae72665d-5d83-48a1-8479-5981a332eccc',
  authorityAzure:
    'https://login.microsoftonline.com/6d88d07c-1672-4d16-ab8c-3af2ef736fe7',
  meAzure: 'https://graph.microsoft.com/v1.0/me',
    nombreEntorno: "Staging"
};
